<template>
  <div class="auth-page">
    <div
      class="w-100 flex-grow-1 d-flex flex-column justify-center align-center"
    >
      <!-- head -->
      <div class="text-center">
        <img
          class="cursor-pointer"
          @click="$router.push('/')"
          :src="require('@/assets/images/global/logo-blue.svg')"
          alt=""
        />
        <span class="font-24 icon-black--text d-block mt-4"
          >Sign in to Ofero</span
        >
      </div>

      <!-- content -->
      <div class="auth-page__content radius-15 pt-8 pb-4 px-5 mt-7">
        <v-form
          v-model="valid"
          ref="signinForm"
          @submit.prevent="submitHandler"
        >
          <!-- email -->
          <div>
            <span class="d-block font-16 icon-black--text">Email Address</span>
            <base-input
              v-model="user.email"
              class="mt-1"
              placeholder="example@gmail.com"
            ></base-input>
          </div>
          <!-- password -->
          <div class="mt-5 d-flex align-center justify-space-between mb-1">
            <span class="d-block font-16 icon-black--text">Password</span>
            <span
              class="blue--text cursor-pointer"
              @click="$router.push('/forget-password')"
              >Forget Password?</span
            >
          </div>
          <base-input
            v-model="user.password"
            type="password"
            placeholder="*********"
          ></base-input>

          <!-- btn -->
          <v-btn
            class="mt-6 radius-15"
            height="45px"
            color="primary"
            elevation="0"
            block
            type="submit"
            :disabled="loading"
            :loading="loading"
            ><span class="white--text font-16">Sign in</span></v-btn
          >
        </v-form>
        <!-- <div class="text-center mt-2">
          <span
            class="font-16 cursor-pointer blue--text d-block"
            v-if="!isAdmin"
            @click="isAdmin = true"
            >Login As Admin</span
          >
          <span
            class="font-16 cursor-pointer blue--text d-block"
            v-if="isAdmin"
            @click="isAdmin = false"
            >Login As User</span
          >
        </div> -->
      </div>

      <!-- bottom btn -->
      <router-link to="/signup" tag="div" class="d-flex w-100">
        <div
          class="w-100 primary-border px-5 py-3 text-center mt-6 radius-15 hover-btn d-flex align-center justify-center"
        >
          <span class="font-16 icon-black--text d-inline-block mr-1"
            >New to Ofero?
          </span>
          <span class="font-16 blue--text font-500">Create an account.</span>
        </div>
      </router-link>

      <div
        class="primary-border px-5 py-3 text-center mt-6 radius-15 hover-btn"
        @click="$router.push('/plans')"
      >
        <span class="font-16 icon-black--text font-16"
          >See Prices and plans
        </span>
      </div>
    </div>
    <div class="privacy-terms-links mt-3 font-16 font-500">
      <a href="https://ofero.co/privecy-and-policy/" target="_blank"
        >Privacy & Policy</a
      >&nbsp;|&nbsp;<a href="https://ofero.co/terms/" target="_blank">Terms</a>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    isAdmin: false,
    user: {},
    loading: false,
  }),
  methods: {
    async submitHandler() {
      let valid = this.$refs.signinForm.validate();
      if (!valid) return;
      this.loading = true;
      let urlApi = process.env.VUE_APP_SIGNIN_USER;
      try {
        const res = await this.$axios.post(urlApi, {
          ...this.user,
        });
        //console.log('res' , res)
        if (res.data) {
          this.loading = false;
          this.$store.dispatch("setUser", res.data);
          this.$store.dispatch("showSnack", {
            text: "logged successfully",
            color: "success",
          });
          if (res.data.user.plan) {
            localStorage.setItem("plan", res.data.user.plan._id);
          } else {
            localStorage.removeItem("plan");
          }

          location.href = "/";
        }
      } catch (err) {
        this.loading = false;
        //console.log('dddddd' , err)
        this.$store.dispatch("showSnack", {
          text: err.message,
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.auth-page {
  max-width: 380px;
  width: 100%;
  position: relative;
  padding: 20px 0px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &__content {
    background: $bg-grey;
  }

  & > div {
    & > * {
      width: 100%;
    }
  }

  .privacy-terms-links {
    a {
      color: black;

      &:hover {
        color: #0195a9;
      }
    }
  }
}
</style>
